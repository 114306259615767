/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';

import MobileLayout from '../../components/templates/MobileLayout';
import MobileSubPageLayout from '../../components/templates/MobileSubPageLayout';
import InfoPage from '../../components/pages/InfoPage';

const Info = () => (
  <MobileLayout>
    <MobileSubPageLayout title="줄눈이란">
      <InfoPage />
    </MobileSubPageLayout>
  </MobileLayout>
);

export default Info;
